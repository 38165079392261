import classNames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';

import Button from 'ui-kit/button/button';
import { ButtonProps } from 'ui-kit/button/button.props';
import ToastBox from 'ui-kit/toast-box/toast-box';

import Disclaimer from 'components/disclaimer/disclaimer.component';

import { BirdiModalIconType } from 'types/birdi-modal-icon';

import BirdiModalIcon from '../BirdiModalIcon';

export interface BirdiModalContentProps {
    icon?: BirdiModalIconType;
    eyebrowText?: string;
    title?: string;
    subTitle?: JSX.Element | string;
    body: JSX.Element | string;
    footnote?: string;
    subtitleClass?: string;
}

export interface BirdiModalContentAltProps {
    title?: string;
    subTitle?: string | TrustedHTML;
    note?: string;
    className?: string;
}

export const OverflowModal = ({
    image,
    title,
    text,
    internalCtas,
    warningText
}: {
    image: GatsbyTypes.Maybe<Pick<GatsbyTypes.File, 'id'>>;
    title: string;
    text: string;
    internalCtas?: ButtonProps[];
    warningText?: string;
}) => {
    return (
        <BirdiModalContent
            icon="none"
            body={
                <div>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <GatsbyImage image={getImage(image)} alt={''} className="overflow-modal-image" />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} className="d-flex flex-column justify-content-center">
                            <div className="overflow-modal-content d-flex flex-column justify-content-center align-items-center align-items-lg-start">
                                <h1 className="text-center">{title}</h1>
                                <div className="spacer" />
                                <div className="modal-body-string" />
                                <p
                                    className="text-center text-lg-left mb-4 h4"
                                    dangerouslySetInnerHTML={{ __html: sanitizeHtml(text) }}
                                />
                                {warningText && <Disclaimer disclaimerText={warningText} />}
                                {internalCtas?.map((cta, idx) => (
                                    <Button
                                        className="mt-3 w-100"
                                        key={`internal-cta-${idx}`}
                                        {...cta}
                                        type={'button'}
                                    />
                                ))}
                            </div>
                        </Col>
                    </Row>
                </div>
            }
        />
    );
};

export const BirdiModalContentAlt = React.memo(({ title, subTitle, note, className }: BirdiModalContentAltProps) => (
    <div className={classNames('birdi-modal-content-container', `birdi-modal-content-container-${className}`)}>
        <h3 className="title">{title}</h3>
        {subTitle && (
            <h4
                className="subtitle"
                dangerouslySetInnerHTML={{
                    __html:
                        typeof subTitle === 'string'
                            ? sanitizeHtml(subTitle, {
                                  allowedTags: ['p', 'br', 'strong']
                              })
                            : subTitle
                }}
            />
        )}
        {note && (
            <ToastBox variant="descriptive">
                <p
                    className="note"
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(note, {
                            allowedTags: ['strong']
                        })
                    }}
                />
            </ToastBox>
        )}
    </div>
));

const BirdiModalContent = React.memo(
    ({ icon, eyebrowText, title, subTitle, body, footnote, subtitleClass = '' }: BirdiModalContentProps) => (
        <div className={'d-flex flex-column align-items-center text-center'}>
            {icon && (
                <div className="my-3">
                    <BirdiModalIcon icon={icon} />
                </div>
            )}
            {title && (
                <>
                    {eyebrowText && <div className="h5 text-uppercase text-primary">{eyebrowText}</div>}
                    <h2 className="text-dark">{title}</h2>
                    <div className="spacer" />
                </>
            )}
            {typeof subTitle === 'string' ? (
                <div
                    className={subTitle && !subtitleClass ? 'mb-5' : `${subtitleClass}`}
                    dangerouslySetInnerHTML={{ __html: sanitizeHtml(subTitle) }}
                />
            ) : (
                <div className={subTitle && !subtitleClass ? 'mb-5' : `${subtitleClass}`}>{subTitle}</div>
            )}
            {typeof body === 'string' ? (
                <div
                    className="modal-body-string"
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(body, {
                            allowedTags: ['strong', 'br']
                        })
                    }}
                />
            ) : (
                <div className="modal-body-content w-100 mb-4">{body}</div>
            )}
            {footnote && <div className="mt-4">{footnote}</div>}
        </div>
    )
);
export default BirdiModalContent;
