import { TFunction, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import { logout, refreshSessionExpiration } from 'state/account/account.reducers';
import { accountIsLoggedInSelector, accountStateSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import TabBroadcast from 'util/tabBroadcast';

import { useGlobalLink } from 'hooks/useGlobalLink';

const SESSION_REFRESH_INTERVAL = 6000;
const TIMEOUT_CHECK_INTERVAL = 6000;
const SESSION_TIMEOUT_CHECK_PADDING = 60000;

export const SessionTimeoutModalContent = ({ translation }: { translation: TFunction<'translation'> }) => {
    return <BirdiModalContentAlt subTitle={translation('modals.sessionTimeout.body')} />;
};

const withSessionExpiration = (Component: any) => (props: any) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const globalLink = useGlobalLink();
    const { handleSignOut } = globalLink;

    //  this determines if a dispatch may be made to update the expiration of the token, necessary to avoid updating expiration too often
    const [nextExpirationRefreshAvailable, setNextExpirationRefreshAvailable] = useState(0); // Initial state is zero to update on page-load
    const [isSessionExpirationModalOpen, setIsSessionExpirationModalOpen] = useState(false);

    const { sessionExpiration } = useSelector(accountStateSelector);
    const isLoggedIn = useSelector(accountIsLoggedInSelector);

    const refreshExpiration = () => {
        //  skip refreshing if the modal is open
        if (!isLoggedIn || isSessionExpirationModalOpen) return;

        const now = Date.now();
        if (now >= nextExpirationRefreshAvailable) {
            //  dispatch update to expiration
            //  update next expiration available
            setNextExpirationRefreshAvailable(now + SESSION_REFRESH_INTERVAL);
            dispatch(refreshSessionExpiration({}));
        }
    };

    const timeoutCheck = () => {
        if (isLoggedIn && Date.now() >= sessionExpiration) {
            handleSignOut();
            dispatch(closeModal({}));
            return;
        }

        //  do timeout check if modal is not open and user is logged in
        //  session timeout padding is what provides the user time to interact with the page before they are logged out
        //  increasing this value will provide more time to interact with the modal
        if (
            isLoggedIn &&
            !isSessionExpirationModalOpen &&
            Date.now() + SESSION_TIMEOUT_CHECK_PADDING > sessionExpiration
        ) {
            dispatch(
                openModal({
                    showClose: false,
                    onClose: () => {
                        refreshExpiration();
                    },
                    type: 'danger',
                    size: 'lg',
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('modals.sessionTimeout.title')} icon="alert" />
                    ),
                    bodyContent: <SessionTimeoutModalContent translation={t} />,
                    ctas: [
                        {
                            label: t('modals.sessionTimeout.submit'),
                            variant: 'primary',
                            onClick: () => {
                                refreshExpiration();
                                dispatch(closeModal({}));
                                setIsSessionExpirationModalOpen(false);
                            },
                            dataGALocation: 'SessionTimeout'
                        }
                    ]
                })
            );
            setIsSessionExpirationModalOpen(true);
        }
    };

    const timeoutCheckInterval = setInterval(timeoutCheck, TIMEOUT_CHECK_INTERVAL);

    useEffect(() => {
        if (document) {
            document.onclick = refreshExpiration;
            document.onkeydown = refreshExpiration;
            document.addEventListener('updatelexstate', refreshExpiration, false); // chatbot events
        }
        return () => {
            document.onclick = null;
            document.onkeydown = null;
            document.removeEventListener('updatelexstate', refreshExpiration, false);
        };
    });

    useEffect(() => {
        return () => {
            clearInterval(timeoutCheckInterval);
        };
    });

    // Setup tab broadcast to communicate
    // between browser tabs.
    useEffect(() => {
        if (isLoggedIn) {
            TabBroadcast.getInstance().callback = () => dispatch(logout());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Component {...props} />;
};

export default withSessionExpiration;
