import { REACT_APP_BOT_VERSION } from 'gatsby-env-variables';
import { ReactElement, useEffect, useState } from 'react';

import ChatbotV1 from './chatbot-v1.component';
import ChatbotV2 from './chatbot-v2.component';

interface ChatBotProps {
    uniqueID: string;
    isInCart?: boolean;
}

const useCookieBannerStatus = (): boolean => {
    const isCookieBannerDisplayed = () => {
        const element = document.querySelector('.cookie-banner');
        if (!element) return false;

        const style = getComputedStyle(element);
        const isVisible = style.display !== 'none' && style.visibility !== 'hidden' && style.opacity !== '0';

        return isVisible;
    };

    const [isDisplayed, setIsDisplayed] = useState(isCookieBannerDisplayed());

    useEffect(() => {
        const checkBanner = () => setIsDisplayed(isCookieBannerDisplayed());
        checkBanner();
        const intervalId = setInterval(checkBanner, 500);
        return () => clearInterval(intervalId);
    }, []);

    return isDisplayed;
};

const Chatbot = (props: ChatBotProps): ReactElement => {
    const isCookieBannerVisible = useCookieBannerStatus();

    return (
        <div className={isCookieBannerVisible ? 'above-banner' : ''}>
            {REACT_APP_BOT_VERSION === 'v2' ? <ChatbotV2 {...props} /> : <ChatbotV1 {...props} />}
        </div>
    );
};

export default Chatbot;
