import classNames from 'classnames';
import { forwardRef } from 'react';
import { Button as ReactButton } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';

// Icons
import BirdiPriceIcon from 'ui-kit/icons/birdi-price/birdi-price-icon';
import CartWhiteIcon from 'ui-kit/icons/cart-white-icon/cart-white-icon';
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import CogIcon from 'ui-kit/icons/cog-icon/cog-icon';
import MapPinIcon from 'ui-kit/icons/map-pin/map-pin-icon';
import SearchIcon from 'ui-kit/icons/search-icon/search-icon';
import TrashIcon from 'ui-kit/icons/trash/trash-icon';

import Camera from '../icons/camera/camera';
import PlusIcon from '../icons/plus-icon/plus-icon';
// Props
import { ButtonProps } from './button.props';
import './button.style.scss';

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const {
        async,
        label,
        children,
        onClick,
        type,
        variant,
        disabled,
        className,
        chevron,
        isBusy,
        dataGAFormName,
        dataGAFormStep,
        dataGAFormStepName,
        dataGAType,
        dataGALocation,
        cameraIcon,
        trashIcon,
        searchIcon,
        plusIcon,
        birdipriceIcon,
        IconType,
        cartIcon,
        separator,
        itemCount,
        style,
        customFontSize,
        mapPinIcon,
        dangerouslySetLabel = false,
        customIcon
    } = props;

    const classes = classNames(className, { 'is-busy': isBusy });
    const carBtnClasses = classNames(
        'btn-label',
        { [`font-size-${customFontSize}`]: customFontSize },
        { 'font-weight-bold mx-1': cartIcon },
        'btn-label-margin'
    );
    return (
        <ReactButton
            ref={ref}
            style={style}
            className={classes}
            variant={variant ?? 'primary'}
            type={type}
            onClick={onClick}
            disabled={disabled}
            data-ga-form-step={dataGAFormStep}
            data-ga-form-stepname={dataGAFormStepName}
            data-ga-form-name={dataGAFormName}
            data-ga-type={dataGAType}
            data-ga-location={dataGALocation}
        >
            {searchIcon && <SearchIcon style={{ paddingBottom: '2px' }} />}
            {mapPinIcon && <MapPinIcon colorHex="#fff" />}
            {cameraIcon && <Camera className="mr-2" style={{ width: '24px' }} />}
            {cartIcon && <CartWhiteIcon itemCount={itemCount} />}
            {chevron === 'left' && <ChevronIcon direction={chevron} />}
            {trashIcon && <TrashIcon />}
            {birdipriceIcon && <BirdiPriceIcon />}
            {plusIcon && <PlusIcon type={IconType} className="btn-plus-icon" />}
            {customIcon && customIcon}
            {!dangerouslySetLabel && <div className={carBtnClasses}>{label}</div>}
            {dangerouslySetLabel && (
                <div
                    className={carBtnClasses}
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(label, { allowedTags: ['strong'] })
                    }}
                />
            )}
            {cartIcon && separator && <span className="divider mx-1 text-white font-weight-bold">|</span>}
            <div className={isBusy ? 'invisible' : 'visible'}>{children}</div>
            {async && <CogIcon className="btn-cog-icon" />}
            {!!chevron && chevron !== 'left' && <ChevronIcon direction={chevron} />}
        </ReactButton>
    );
});

export default Button;
